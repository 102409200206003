import { useState } from 'react'


import '../css/title.css'

export default function TitleLft(props) {

    let title= props.titlename;
  const [titlevalue, setnewtitle] = useState(title)

  let colour = props.colour;
    
    
  const dynamicStyle = {
    
    background: colour, 
   }

  return  (
  <>
    <div className="titleleft" id="title" style={dynamicStyle} >
      
     
      <h1 className="titlenameleft"  >{titlevalue}</h1>
      
      <img src={"/images/bottom stroke.svg"}   className="underline"  title="image of jay"/>
     
     
    </div>
  </>
  )
}