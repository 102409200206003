import { useState, useEffect } from 'react'

import '../css/form.css'
import emailjs from '@emailjs/browser';

const sendEmail = (e) => {
  e.preventDefault();
  alert("submitted");

  emailjs.sendForm('service_i27haxs', 'template_r3y4gt8', e.target, 'tbCi_FSiCocj-Cap8');
}


export default function Form() {

  var dynamicStyle = {
    
    top: "500px" ,
    left: "100px",
     
   }

   var dynamicStyleBottom = {
    
    bottom: "50px" ,
    left: "100px",
     
   }

   const [upmove, setUpMove] = useState()
   const [downmove, setDownMove] = useState()
   let xPx = 0;
   useEffect(() => {
    // call api or anything
    

    let height = document.getElementById('formcontainer').clientHeight;
    let pagewidth = window.screen.width;
    
   console.log(height)
   console.log(pagewidth)

   
    let offset = height-750;
    let downoffset = height-750;
   console.log(offset)
    setUpMove(offset)
    setDownMove(downoffset)






 });

  return  (
  <>
  
    <div className='formdetails'>

         <div className='formcontainer' id='formcontainer' >

         <img src={"/images/top stroke.svg"} className="formbrushtop" 
               
                 title="paintstroke" />

            <form id="contact-form" onSubmit={sendEmail}  autoComplete="off">
                <div className="form-group">
                    <label htmlFor="name" className='namelabel'>Email</label>
                    <br></br>
                    <input type="text" name="from_name" className="form-control" id='name' autocomplete="off"/>
                </div>
                
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <br></br>
                    <textarea className="form-control" rows="5" name='message'></textarea>
                </div>
                <button  type='submit'  className="btn btn-primary" >Message</button>
            </form>

            <img src={"/images/bottom stroke.svg"} className="formbrushbottom"  title="paintstroke"
                />
      

        </div> 

       
    </div>
  </>
  )
}