import React from 'react'
import ReactDOM from 'react-dom/client'

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Space from './space.jsx';

export const HomePageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
    <App
    logo = {"/images/paperboat logo small green.png"} />
    
    <Jay />

    <Block details={"I have always been passionate about all things creative. With a technical background, my motto has always been, “I can learn to do that,” which has led me to develop a wide range of skills across various disciplines. I have worked in both full-time employment and freelance capacities for multiple companies on game, video, and web projects.I am always eager to learn new things and bring that enthusiasm to every project I work on. My ability to quickly adapt and acquire new skills allows me to contribute effectively and support the success of the team"}
    margin={100}/>
    
    <Title title={"How can i help you?"}/>

    <Help/>

    <TitleLft titlename={"Education"}/>

    <Certification 
    logo={"./images/ucalogo.svg.png"} 
    title={"1st in techinical digital media"} 
    description={"Studying technical digital media for film, games, and web, I consistently achieved top marks and graduated with a first-class degree. My projects were frequently recognized for their excellence."}
    />

    <Title title={"Work experience"}/>

    <Experience company={"CV-Library"} 
    date={"April 2019 - Now"}
    role={"Technical Animator & deveolper"}
    experience = {"Creating animations and developing websites for a recruitment company, including the creation of custom web adverts for clients, social media content, and outdoor advertising."}
    logo= {"./images/complogos/cvl.jpg"}
    />

    <Experience company={"The BookPeople"} 
      date={"Feb 2018 - March 2019"}
      role={"Technical Animator & deveolper"}
      experience = {"Creating animations, videos, and developing websites for a book company. This includes designing engaging digital content to promote books, enhancing user experience on the company’s website, and producing high-quality visual media to support marketing campaigns."}
      logo= {"./images/complogos/thebookpeople.webp"}
      />

    <Experience company={"Sightline"} 
      date={"June 2017- December 2018"}
      role={" 2D/3D animator and editor"}
      experience = {"Creating 2D and 3D animations for clients such as the NHS, and editing showreels for leading construction firms."}
      logo= {"/images/complogos/sigthline.png"}
      />

    

    <TitleLft titlename={"Freelance Projects"}/>

    <Projects
    projectname = {["Whitwam AV solutions",
       "University of creative arts",
        "Food waste charity film",
         "Yvonne Arnaud Theatre",
         "G Live",
          "BT Vanarama",
            
          "NRG Digital",
              "Innis & Gunn",
                "“Alone in a crowd” Music video",
                  "Animal and plant health agency",
                   "Barbaric barbarian",
                    "Hope"]}
    projectdescription = {[
      "Set up live playback of an orchestra, including microphones,lighting and monitors, worked as a camera assistant",
        "Filmed and edited a live business event at a UK university",
          "Filmed and edited a short documentary working with a PR company",
            "Recording and amplifying live sound and setting up equipment",
              "Worked as a backstage technician setting up shows at an events venue",
                "Filmed live football at Aldershot Football Club with training from BT",
                
                "Editing and motion graphics for online client testimonials",
                "Created a proof of concept film using After Effects",
                  "Created VFX and did compositing for music video",
                    "Creation of instructional videos for the civil service",
                      "Created character animation and VFX for main character in game.",
                        "Created VFX for top down fighting game"]} 

    projectlogo = {["./images/complogos/whitwam.jpg",
                  "./images/complogos/uca.png",
                  "./images/complogos/foodwaste.jpg",
                  "./images/complogos/yvonne.png",
                  "./images/complogos/glive.png",
                  "./images/complogos/bt.png",
                  "./images/complogos/nrg.png",
                  "./images/complogos/innis.jpg",
                  "./images/complogos/alone.webp",
                  "./images/complogos/apha.png",
                  "./images/complogos/barbarian.png",
                  "./images/complogos/hope.jpg"
      
    ]}  />



    <Reviews 
    number = {3}

    review = {["Jay was an exceptional colleague to collaborate with.", "",
              "Highly professional and consistently delivers top-quality work.", "",
              "I am absolutely certain I will work with him again."
    ]} 
    
    reviewer = {["Keith - Sightline","",
                "Rob Davies - APHA", "",
                "Dan K - CV-Library", ""
    ]}/>

    <Contact/>

  </React.StrictMode>,
)

)
}