import React from 'react';

import { NodeGroup } from 'react-move';


import { useState, useEffect } from 'react'

import '../css/character.css'


var movedown = 0;
var moveleft = 0;

export default function Character() {

    const isMobile = navigator.userAgentData.mobile;

const [characterPosition, setCharacterPosition] = useState(0);
  
const [characterPositionleft, setCharacterPositionleft] = useState(0);
  
const [characteranimation, setcharateranimation] = useState("https://giphy.com/embed/rKasWqZnOQhZhiqqVU");
  
const [direction, setdirection] = useState(1);

if (isMobile == false){

document.onkeydown = function(event){

   setcharateranimation("https://giphy.com/embed/wIxsX3M2iiU74FliIk");
    console.log("Key pressed: " + event.key);
    
    if (event.key === 's'){
        setCharacterPosition(movedown);
        movedown +=20;
        
    }
    if (event.key === 'w'){
        setCharacterPosition(movedown);
        movedown -=20;
        
    }
    if (event.key === 'a' ){
        setCharacterPositionleft(moveleft);
        moveleft -=20;
        setdirection(-1);
        document.getElementById('mecharacter').style.transform = "scaleX(1)";
    }

    if (event.key === 'd'){
        setCharacterPositionleft(moveleft);
        moveleft +=20;
        document.getElementById('mecharacter').style.transform = "scaleX(-1)";
    }
   
    
    
   

}; 


document.addEventListener("keyup", function(event) {

  //console.log("Key pressed: " + event.key);
  setcharateranimation("https://giphy.com/embed/rKasWqZnOQhZhiqqVU");

  
}); 

}


//touch for character



const [finished, setfinished] = useState("yes");

if(finished =="yes"){
document.addEventListener('touchstart', e => {
    
[...e.changedTouches].forEach(touch => {
    setfinished("no")
    const character = document.getElementById("character");
    const charactervid = document.getElementById("mecharacter");
    setcharateranimation("https://giphy.com/embed/wIxsX3M2iiU74FliIk");

    var scroll = window.scrollY 
    
    character.style.top = `${touch.pageY- scroll}px`
     character.style.left = `${touch.pageX}px`
    
 
     var delayInMilliseconds = 1000; //1 second

     setTimeout(function() {
        setcharateranimation("https://giphy.com/embed/rKasWqZnOQhZhiqqVU");
        setfinished("yes")
     }, delayInMilliseconds);     
          

})



})

}





       
          
      




  return  (
  <>
 
    <div className='characterdiv' id='characterdiv' style={{top:characterPosition, left:characterPositionleft}} >

    
      
        <div className='character' id='character' >
            <div >
            <iframe src={characteranimation} width="100%" height="100%"  className="mecharacter" id='mecharacter'>
            </iframe></div>
           
        </div>

    </div>
      

   
  </>
  )
}