
import '../css/projects.css'


export default function Project(props) {

  
    var role = props.role;
   
    var experience = props.experience;

    var logo = props.logo;
    
      return  (
      <>
      
        <div className='project'>
    
        <div className='dotcontainer'>
            <span className="projectdot">
            <img src={logo}   className="logoimage" onerror="this.style.display='blank'" />
            </span>
        </div>
        <div className='projectinfo'>
          <h4 className='expriencetitle'>{role}</h4>
          <p className='experiencedescription'>{experience}</p>
        </div>

    
          
        </div>
      </>
      )
    }