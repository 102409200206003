import { useState } from 'react'

import '../css/certification.css'



export default function Certification(props) {

  var logo = props.logo;
  var title = props.title;
  var description = props.description;

  return  (
  <>
  <div className = "description">
     


      <span className='certification' >

      <div className='descriptionlogo'>
        <img src={logo} className="certlogo"  title="logo" />
      </div>

        <div className='descriptiontext'>
          <h2 className="certtitle">{title}</h2>
          <p className="certdescription">{description}</p>
        </div>

      </span>
    </div>
  </>
  )
}