import { useState } from 'react'

import '../css/video.css'
import React, { Component } from 'react'
import ReactPlayer from 'react-player'


export default function Video(props) {

  var src = props.source;
  var description = props.descriptionvid;

  return  (
  <>
  
<div className='videodiv'>
  <span className='videocontainer'>



  <div className='player'>
            <ReactPlayer
            className='video'
            url= {src}
            width= '100%'
            playing
            playIcon={"true"}
           controls = {"false"}
           loop={"true"}
           muted
         

            />
    </div>
    <span className='descriptionvideoright'>
      <p className='showreeldescriptionright' >{description}</p>
    </span>
    
    <img src={"/images/paint mark-01.png"} className="videopaintmark"  title="paintmark"/>

  </span>   

   
</div>
  </>
  )
}