import { useState } from 'react'


import '../css/title.css'

export default function Title(props) {

    let title= props.title;
  const [titlevalue, setnewtitle] = useState(title)


  return  (
  <>
    <div className="title" id="title"  >
      
     
      <h1 className="titlename"  >{titlevalue}</h1>
      
      <img src={"/images/bottom stroke.svg"}   className="underline"  title="image of jay"/>
     
     
    </div>
  </>
  )
}