import { useState } from 'react'

import "@fontsource/montserrat";
import '../css/toptitle.css'

export default function Toptitle(props) {

    let title= props.title;


  return  (
  <>
    <div className="toptitle" id="toptitle"  >
      
     
      <h1 className="toptitlevalue"  >{title}</h1>
      
     
     
    </div>
  </>
  )
}