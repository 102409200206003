import { useState } from 'react'

import Title from './title.jsx'

import '../css/help.css'
import Subject from './subject.jsx'
import Info from './info.jsx'


export default function Help() {



  return  (
  <>
    <div className="help" id="help">

    <div className='helpcontainer'>
      <div className='subjects'> 

        <Subject title={"Videography"} description={"Producing live-action videos, including both filming and editing."} icon={"/images/play.svg"}/>
        <Subject title={"Animation"}  description={"Creating exciting animations for your project, ranging from motion design to fully animated stories."} icon={"/images/aniamtion.svg"}/>
        <Subject title={"Game Animation"}  description={"Developing animations and assets for game projects and integrating them into Unreal Engine or Unity."} icon={"/images/gamepng.png"}/>
        <Subject title={"Front end web"}  description={"Designing websites and developing the front-end code, including interactive elements."} icon={"/images/webpng.png"}/>
      </div> 

      <div className='info'>
        <Info/>
      </div>

    </div>
    </div>
  </>
  )
}