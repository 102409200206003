import { useState } from 'react'

import '../css/experience.css'



export default function Experience(props) {

var company = props.company;
var role = props.role;
var dates = props.date;
var experience = props.experience;
var logo = props.logo

  return  (
  <>
  
    <div className='experience'>

      <div className='experiencecontainer'>


        <span className='experienceleft'>
        <img src={logo} className="experiencelogo" height="20" title="this is a photo"/>
            <div className='companyinfo'>
              <h2 className='company'>{company}</h2>
              <p className='dates'>{dates}</p>
            </div>
        </span>

        <span className='experienceright'>
          <span className="dot"></span>
          <div className='experienceinfo'>
            <h4 className='expriencetitle'>{role}</h4>
            <p className='experiencedescription'>{experience}</p>
          </div>

        </span>

      </div>
      
    </div>
  </>
  )
}