import React from 'react'
import ReactDOM from 'react-dom/client'

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Toptitle from './toptitle.jsx';
import Showreel from './showreel.jsx';
import Skills from './skills.jsx';
import Screen from './screen.jsx';
import Video from './video.jsx';
import Videolft from './videolft.jsx';
import Ball from './ball.jsx';
import Tutorial from './tutorial.jsx';
import Character from './Character.jsx';

export const GamesPageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
    <App 
     logo = {"/images/paperboat logo-01.png"}/>
    
   <Toptitle title={"Games"}/>

  <Tutorial/>

  <Block
   margin={'200px'}
   details={"As a solo developer and technical animator, I bring a unique blend of skills to game projects, handling everything from coding to creating intricate animations. My ability to manage all aspects of development ensures a cohesive and polished final product. I excel at turning creative visions into playable realities, crafting engaging gameplay mechanics, and animating characters and environments to enhance the gaming experience. My dedication to quality and attention to detail make me a valuable asset to any game development project."}
    />

<Title title={"Development"}/>

<Showreel 
  source={"https://www.youtube.com/embed/iNq8nJ6WItw?si=wdc7884HUALKuXzC"}
  description={["As a solo developer, I manage all aspects of the development process, from initial concept to final deployment. My diverse skill set allows me to handle coding, design, and technical animation, ensuring a cohesive and high-quality product. I thrive on the challenge of bringing creative ideas to life independently, crafting engaging and functional applications or games. ", "My ability to work autonomously and efficiently makes me a versatile and reliable asset for any project."]}
  version={"handwritten"}
  align = {"auto"}
  center = {"center"}/>

<TitleLft titlename={"Technical Animation"} />

<Showreel 
  source={"https://www.youtube.com/embed/9--QI7ukZSg?si=H6BwNkrSGyouAU8_"}
  description={["As a technical animator, I bridge the gap between art and technology, ensuring that animations are both visually stunning and technically sound. I work closely with artists and developers to create smooth, realistic animations that enhance the overall user experience. ", "My expertise includes rigging characters, optimizing animation workflows, and implementing animations into various platforms. Whether it’s for games, films, or interactive media, I ensure that every movement is precise and lifelike, contributing to the project’s success."]}
  version={"handwritten"}
  align = {"auto"}
  center = {"center"}/>

<Title title={"Modeling"}/>

<Showreel 
 source={"https://www.youtube.com/embed/tCKAHdi41ds?si=C3g0LIh2E-s37FfK"}
 description={["I specialize in creating high-quality 3D assets for games, bringing virtual worlds to life with detailed and realistic models. My process involves designing and sculpting characters, environments, and objects that enhance the visual appeal and immersion of the game.", " I ensure that each asset is optimized for performance without compromising on detail, using advanced software and techniques. My work contributes to creating engaging and visually stunning gaming experiences that captivate players."]}
 version={"handwritten"}
 align = {"auto"}
  center = {"center"}/>

<Projects
    projectname = {["Barbaric barbiarian",
       "Trick or Treat",
        "Angry Director",
       "Hope",
              "Subway excape"
                  ]}
    projectdescription = {[
      "Created the Animation and implemenet the Technical animation of the main character in an upcoming barbiarian game",
        "Developed a Halloween game using Blueprints in Unreal Engine.",
          "Handled physics and animation for a film set-style game.",
     
                "Creating technical animations for an upcoming top-down game.",
                "Creating technical animations for a subway-themed puzzle game."
               
                     ]} 

    projectlogo = {["/images/complogos/barbarian.png",
                  "/images/complogos/dino.jpeg",
                  "/images/complogos/dir.jpg",
                  
                 
                 
                  "/images/complogos/hope.jpg",
                  "/images/complogos/sub.png"
                  
                  
                 
                 
      
    ]}  />

    
<Title title={"Skills & software"}/>

<Skills
skills = {["Programming",
   "C++",
    "Technical Animation",
     "VFX",
    "SFX",
   "Modeling and layout",
    "Cinematics",
     "Blueprints"]}
logo={["/images/unreal.jpeg",
   "/images/unity.png",
    "/images/maya.png",
    "/images/blender.png"]}
software={["Unreal Engine", 
"Unity", 
"Maya", 
"Blender"]}/>

<Contact/>

<Character/>


</React.StrictMode>,
)

)
}