import { useState } from 'react'


import '../css/space.css'

export default function Space() {

  


  return  (
  <>
    <div className="space" id="space"  >
      
     
     
      
     
    </div>
  </>
  )
}