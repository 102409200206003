import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {HomePage } from './pages/HomePage';
import {VideoPage } from './pages/VideoPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { AnimationPage } from './pages/AnimationPage';
import { GamesPage } from './pages/GamesPage';
import { WebPage } from './pages/WebPage';
import { GraphicsPage } from './pages/GraphicsPage';
import { ContactPage } from './pages/ContactPage';


export const Routes = () => {
    return (
    <Router>
        <Switch>

            <Route path="/" exact>
                <HomePage />
            </Route>

            <Route path="/videography" exact>
                <VideoPage />
            </Route>


            <Route path="/animation">
                <AnimationPage />
            </Route>

            
            <Route path="/games">
                <GamesPage />
            </Route>

            <Route path="/web">
                <WebPage />
            </Route>

            <Route path="/graphics">
                <GraphicsPage />
            </Route>

            
            <Route path="/contact">
                <ContactPage />
            </Route>

            
            <Route>
                <NotFoundPage />
            </Route>

        </Switch>
    </Router>

    )
}