import { useState } from 'react'


import '../css/graphicimages.css'

export default function Graphicimage(props) {

var description = props.description

var image1 = props.imageone;
var image2 = props.imagetwo;
var image3 = props.imagethree;
    
   
  return  (
  <>
    <div className="graphicimages" >
      
        <div className='imagescontainer' >

        <img src={"/images/paint mark-01.png"} className="videopaintmark" id='paintleft'  title="paintmark"/>

            <img src={image1} className="square"  title="graphicimage"/>

            <img src={image2} className="tall"  title="graphicimage"/>

            <img src={image3} className="wide"  title="graphicimage"/>
       
       
       
        <img src={"/images/paint mark-01.png"} className="videopaintmark" id='paintright'  title="paintmark"/>


       
        </div>
     
        <p className='graphicdescription' >{description}</p>     

    </div>
  </>
  )
}