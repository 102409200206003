import { useState } from 'react'

import '../css/contactdetails.css'



export default function Contactdetails() {

   

  return  (
  <>
  
    <div className='contactdetails'>

         <div className='contactcontainer' >

            <p className='contactmessage'>Interested in collaborating? Drop me a message!</p>
            <p className='email'>jaysonhayes@outlook.com</p>
            <p className='phonenumber'>07985152891</p>

            <img src={"/images/paint mark-01.png"} className="paintmark" id='leftpaint' title="image of jay"/>
            <img src={"/images/paint mark-01.png"} className="paintmark" id='rightpaint' title="image of jay"/>
            
        </div> 
    </div>
  </>
  )
}