

import { HomePageComponent } from '../components/homeMain'

import '../css/index.css'

export const HomePage = () => {
    return (
      
        
        <HomePageComponent/>
      
        
       
    )
}