import { useState } from 'react'

import '../css/contact.css'



export default function Contact() {

   

  return  (
  <>
  
    <div className='contact'>

         <div className='buttoncontainer' >
         <a href="./contact">
            <button className='button' type='image'
            onclick="document.location='contact.html'">Contact me today
            
              <img src={"/images/top stroke.svg"}   className="buttonimage"  title="button"/>
            </button>
            </a>
          </div> 
    </div>
  </>
  )
}