import React from 'react'
import ReactDOM from 'react-dom/client'

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Toptitle from './toptitle.jsx';
import Showreel from './showreel.jsx';
import Skills from './skills.jsx';
import Screen from './screen.jsx';
import Video from './video.jsx';
import Videolft from './videolft.jsx';
import Ball from './ball.jsx';

export const AnimationPageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
    <App
     logo = {"./images/paperboat logo-01.png"} />
    
   <Toptitle title={"Animation"}/>

  <Screen/>
   
   <Block
   margin={'200px'}
   details={"I have extensive experience in creating both 2D and 3D animations for a variety of companies and projects. My work spans across different industries, allowing me to bring unique and creative solutions to each project. From concept development and storyboarding to the final rendering, I ensure that every animation is visually engaging and meets the client’s objectives. My technical skills in animation software, combined with a keen eye for detail and a strong sense of storytelling, enable me to produce high-quality animations that captivate audiences and effectively convey the intended message."}
    />

  <Title title={"Motion Graphics"}/>

  <Video
  source={"./images/animation/motion graphics.mp4"}
  descriptionvid={"I excel in creating dynamic motion graphics that bring visual elements to life. My expertise includes designing and animating graphics for a variety of media, from promotional videos and advertisements to social media content and presentations. With a strong understanding of design principles and animation techniques, I create visually appealing and engaging motion graphics that effectively communicate the intended message. My attention to detail and creativity ensure that each project stands out and captures the audience’s attention"}
  />

<TitleLft titlename={"Logo animation"}
colour ={"white"}/>

<Videolft
  source={"./images/animation/Logo animation.mp4"}
  descriptionvid={"I animate logos to bring them to life with dynamic transitions and creative effects. My animations help brands stand out and make a lasting impression, whether for websites, video intros, or social media."}
  />

<Title title={"Characters"}/>

<Video
source={"./images/animation/character.mp4"}
descriptionvid={"I bring characters to life through animation, creating expressive and engaging movements that enhance storytelling. My expertise in both 2D and 3D animation allows me to develop characters that resonate with audiences, whether for films, games, or advertisements. Each character animation is crafted with attention to detail, ensuring lifelike and captivating performances."}
/>

<TitleLft titlename={"2D animation"}
colour ={"white"}/>

<Videolft
  source={"./images/animation/2d animation square.mp4"}
  descriptionvid={"I create and animate 2D assets, bringing static designs to life with fluid and engaging movements. My process involves designing detailed and visually appealing assets, then animating them to enhance storytelling and user experience. Whether for games, videos, or interactive media, my 2D animations add depth and dynamism to each project."}
  />

<Title title={"3D animation"}/>

<Video
source={"./images/animation/3d.mp4"}
descriptionvid={"I create and animate 3D assets, turning concepts into detailed models with realistic movements. My 3D animations enhance visual experiences in games, films, and virtual reality projects."}
/>

<TitleLft titlename={"Video projects"}
colour ={""} />

<Projects
    projectname = {["CV-Library",
       "The book People",
        "Sightline",
         
        
         
            
          "NRG Digital",
              "Innis & Gunn",
                "“Alone in a crowd” Music video",
                  "Animal and plant health agency"
                  ]}
    projectdescription = {[
      "Creating dynamic motion graphics to enhance CV-Library’s digital content and engage job seekers.",
        "Creating captivating social animations to enhance TheBookPeople’s online engagement and storytelling.",
          "Filmed and edited a short documentary working with a PR company",
           
             
               
                
                "Motion graphics for online client testimonials",
                "Created a proof of concept film using After Effects",
                  "Created VFX and did compositing for music video",
                    "Crafting engaging motion graphics and 3D animations to simplify processes for the Animal and Plant Health Agency.",
                     ]} 

    projectlogo = {["./images/complogos/cvl.jpg",
                  "./images/complogos/thebookpeople.webp",
                  "./images/complogos/sigthline.png",
                  
                 
                 
                  "./images/complogos/nrg.png",
                  "./images/complogos/innis.jpg",
                  "./images/complogos/alone.webp",
                  "./images/complogos/apha.png"
                 
                 
      
    ]}  />
<Title title={"Skills & software"}/>

<Skills
skills = {["2D animation", "3D animation", "rigging", "3D modeling", "character animation", "vfx animation"]}
logo={["./images/ae.png", "./images/toon.jpeg",
   "./images/blender.png", "./images/cinema.png"
   ,"./images/maya.png", "./images/houdini.png",
    "./images/unreal.jpeg"]}
software={["After effects", "Toon boon harmony", "Blender", "Cinema 4d", "Maya", "houdini", "Unreal"]}/>

<Contact/>


<Ball/>



</React.StrictMode>,
)

)
}