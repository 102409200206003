import React from 'react'
import ReactDOM from 'react-dom/client'

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Toptitle from './toptitle.jsx';
import Showreel from './showreel.jsx';
import Skills from './skills.jsx';
import Screen from './screen.jsx';
import Video from './video.jsx';
import Videolft from './videolft.jsx';
import Ball from './ball.jsx';
import Tutorial from './tutorial.jsx';
import Built from './built.jsx';
import Website from './website.jsx';
import Graphicimage from './graphicimages.jsx';
import Contactdetails from './contactdetails.jsx';
import Form from './form.jsx';
import Middlemessage from './middlemessage.jsx';
import Space from './space.jsx';

export const ContactPageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
    <App
    logo = {"/images/paperboat logo-01.png"} />
    
   <Toptitle title={"CONTACT ME"}/>

    <Contactdetails/>

 <Form/>

 <Middlemessage/>

<Space/>

</React.StrictMode>,
)

  )}