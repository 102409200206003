import { useState, useEffect } from 'react'

import '../css/block.css'

export default function Block(props) {

  let details = props.details ;
  let margin = props.margin;

 /* const dynamicStyle = {
    marginTop: margin, 
     
   }
   const [upmove, setUpMove] = useState()
   const [downmove, setDownMove] = useState()
   let xPx = 0;
   useEffect(() => {
    // call api or anything
    

    let height = document.getElementById('block').clientHeight;
    let pagewidth = window.screen.width;
    
   console.log(height)
   console.log(pagewidth)

   
    let offset = height-330;
    let downoffset = height-20;
   console.log(offset)
    setUpMove(offset)
    setDownMove(downoffset)


 });
   */
   


  return  (
  <>
    <div className='block'  >
      
          
            <div className="textblock" id="block">
                <div className='blockimage'>
                  <img src={"/images/Block.svg"} className="blockimageinside"/>
                  
                </div>
                
                <p className="about">{details}</p>
                
    
            </div>
       <div className='whiteblock'></div>
    </div>
  </>
  )
}