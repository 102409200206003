import { useState } from 'react'

import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';

import '../css/built.css'

var divtext = "<div>"


export default function Built() {

  
  
    
    



  return  (
  <>
    <div className='built' >

     

        <div className="builtblock" >

            <div className='builttext'>
            <p className="divtext">{divtext}</p>
            <p className="builtin">I created this site using Figma for design, and built it with HTML, CSS, and JavaScript. The frontend is powered by React, while the backend is developed with Node.js</p>
               
            </div>
      
            <div className='weblogos'>
            <img src={"/images/figma.png"} className="figma"  title="paintstroke"/>
            <img src={"/images/html.png"} className="html"  title="paintstroke"/>
            <img src={"/images/css.png"} className="js"  title="paintstroke"/>
            <img src={"/images/js.png"} className="react"  title="paintstroke"/>
            <img src={"/images/react.png"} className="node"  title="paintstroke"/>
            <img src={"/images/node.png"} className="node"  title="paintstroke"/>
            </div>

            

        </div>
      
    </div>
       
   
  </>
  )
}