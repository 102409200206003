import { useState } from 'react'

import '../css/projects.css'

import Project from './project'

export default function Projects(props) {

var name = props.projectname;
var description = props.projectdescription;
var logo = props.projectlogo;
var i = 0;

  return  (
  <>
  
    <div className='projects'>

      <div className="projectscontainer">

        <span className='projectleft'>

            <Project role={name[i]} experience={description[i]} logo={logo[i]}/>

            <Project role={name[1]} experience={description[1]} logo={logo[1]}/>
            
            <Project role={name[2]} experience={description[2]} logo={logo[2]}/>

            <Project role={name[3]} experience={description[3]} logo={logo[3]}/>

            <Project role={name[4]} experience={description[4]} logo={logo[4]}/>

            <Project role={name[5]} experience={description[5]} logo={logo[5]}/>

        </span>

        <span className='projectright'>

          <Project role={name[6]} experience={description[6]} logo={logo[6]}/>

          <Project role={name[7]} experience={description[7]} logo={logo[7]}/>

          <Project role={name[8]} experience={description[8]} logo={logo[8]}/>

          <Project role={name[9]} experience={description[9]} logo={logo[9]}/>

          <Project role={name[10]} experience={description[10]} logo={logo[10]}/>

          <Project role={name[11]} experience={description[11]} logo={logo[11]}/>
        </span>     
            
      </div>
    

      
    </div>
  </>
  )
}