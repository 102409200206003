import { useState , useRef, useReducer} from 'react'

import '../css/webdevice.css'




export default function Webdevice(props) {

  var url = props.url;
  var link = props.link;
  var webdescription = props.description;
  var device = props.device;
  var width = props.width;
  var height = props.height;
  var transforming = props.transform;
  var newtransform = props.newtransform;
  var newheight = props.newheight;

  const sliderRef = useRef(null);
  const scrollAmount = 300; // The amount to scroll when clicking the navigation buttons
  const [images, setImages] = useState([
    {url : url[0]  },
    {url : url[1] },
    {url : url[2]  },
    {url : url[3]  },
    {url : url[4]  }
    
  ]);

  const [slide, setSlide] = useState("0")

  let xPx = 0;


  const [arrowvisableL, setarrowvisableL] = useState("block")

  var dynamicStyleL = {
    display: arrowvisableL,  
   }

   const [arrowvisableR, setarrowvisableR] = useState("block")

  var dynamicStyleR = {
    display: arrowvisableR,  
   }

   const backgroundStyle = {
    backgroundImage: device,
    
  };

  const [, forceUpdate] = useReducer(x => x + 1, 0);

 
    var screenWidth = window.innerWidth;
    if(screenWidth > 1500){
      
      transforming = props.newtransform //needs to be usestate
      height = props.newheight;
      
      //forceUpdate;  //removed this
     }

   


 
 

 /* if(screenWidth > 1500){



   height = height.slice(0, -2);
  height = Number(height);
  height = height ;
   var heightcontainer = String(height) + "vw"

  
   
  }*/

   let webdescriptionList = [];

   webdescription.forEach((description, index) => {
    webdescriptionList.push(<li key={index}>{description}</li>);
  });

  return  (
  <>
  
  <div className='webdevice'>
  
  <a className='link' href={link}>{link}</a>
 
    <div className='devicecontainer' 
    style={backgroundStyle}>
        
        <div className='screen' style={{width: width, height: height, transform: `translateX(${xPx}px) translateY(${transforming}vw)`}}>
            <div className='screenimages'
            style={{transform: `translateX(${slide}%) translateY(${xPx}px)`}}>
            {images.map((image) => {
            return (
              <img
                className="imageinside"
                id='imageinside'
                alt="sliderImage"
                key={image?.id}
                src={image?.url}
                
              />
            );
          })}
            </div>

           
        </div>

        <button
          className="navbtnR"
          style={dynamicStyleR}
          onClick={() => {
            setarrowvisableL("block")
          if(slide > (images.length-1)*-100){
            setSlide(slide -"100")
          }
          

          else{
            setarrowvisableR("none")
          }
          
        
          
          console.log(slide)
         
          }}
        >
           <img src="/images/arrow.svg" aria-hidden="true" className='arrowr' ></img>
        </button>

        <button
          className="navbtnL"
          style={dynamicStyleL}
          onClick={() => {
            setarrowvisableR("block")
        
            setSlide(0)
            
            console.log(slide)
            setarrowvisableL("none")
         
          }}
        >
           <img src="/images/arrow.svg" aria-hidden="true" className='arrowl' ></img>
        </button>

        
      
        

    </div>


    <div className='desbox'>
    <p className='webdescription'>
      
    <ul  className='showreellist' >
            <li   className='showreellist'>{webdescriptionList}</li>

          </ul></p>
    </div>

  </div>

  </>
  )
}