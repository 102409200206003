import { useState , useEffect} from 'react'

import '../css/tutorial.css'
import { ConstantColorFactor } from 'three';




export default function Tutorial() {
  


 
 
  var is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/ig) || false;
  const isMobile = navigator.userAgentData.mobile;

  var clickcontrol = "none"
  var controlbuttons = "none"
  var control =  "control with ywsad"

if (isMobile == true){
  control = "click to move character";
 controlbuttons = "none"
 clickcontrol = "block"
}
else{
  control = "Control the Character with WSAD keys"
 controlbuttons = "block"
 clickcontrol = "none"
}

  const [pressedS, setpressedS] = useState("")
  
  var dynamicStyleS = {
    scale: pressedS,  
   }

   const [pressedW, setpressedW] = useState("")
  
   var dynamicStyleW = {
     scale: pressedW,  
    }

    const [pressedA, setpressedA] = useState("")
  
    var dynamicStyleA = {
      scale: pressedA,  
     }

     const [pressedD, setpressedD] = useState("")
  
     var dynamicStyleD = {
       scale: pressedD,  
      }
 





  document.addEventListener("keydown", function(event) {

    console.log("Key pressed: " + event.key);
    
    if (event.key === 's'){
     setpressedS("1.2");
    }
    else if (event.key === 'w'){
      setpressedW("1.2");
     }
     else if (event.key === 'a'){
      setpressedA("1.2");
     }
     else if (event.key === 'd'){
      setpressedD("1.2");
     }
    
}); 


document.addEventListener("keyup", function(event) {

  console.log("Key pressed: " + event.key);
  
   setpressedS("1")
   setpressedW("1")
   setpressedA("1")
   setpressedD("1")
  
}); 


const [touched, settouched] = useState("")
  
  var dynamicStyletouch = {
    scale: touched,  
   }


window.addEventListener('touchstart', function onFirstTouch() {
 
  settouched("1.2");

  // we only need to know once that a human touched the screen, so we can stop listening now
  window.removeEventListener('touchstart', onFirstTouch, false);
}, false);

window.addEventListener('touchend', function onFirstTouch() {
 
  settouched("1");

  // we only need to know once that a human touched the screen, so we can stop listening now
  window.removeEventListener('touchstart', onFirstTouch, false);
}, false);




  return  (
  <>
    <div className='tutorial' >

     

        <div className="explainblock" >
            <p className="explain">{control}</p>
        </div>
      
        <div className='arrows' style={{ display: controlbuttons }}>
          <img src={"/images/w.svg"} className="up" id='up' title="paintstroke" style={dynamicStyleW} />
          <img src={"/images/s.svg"} className="down" id='down' title="paintstroke" style={dynamicStyleS}/>
          <img src={"/images/a.svg"} className="left"  title="paintstroke" style={dynamicStyleA}/>
          <img src={"/images/d.svg"} className="right"  title="paintstroke" style={dynamicStyleD}/>
        </div>

        <div className='arrows' style={{ display: clickcontrol } }>
          <img src={"/images/handsvg.svg"} className="click"   style={dynamicStyletouch}  />
         
        </div>
      
        </div>
       
   
  </>
  )
}