import { useState } from 'react'
import ReactDOM from "react-dom/client";

import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls, SoftShadows } from "@react-three/drei";

import '../css/screen.css'
import Showreel from './showreel';

function Model(props) {
  const { scene } = useGLTF("/laptop.glb"); //file here
  return <primitive object={scene} {...props} />
}

var showing = "none"









export default function Screen() {

  const [showing, setshowing] = useState("none")
  const [showingtext, setshowingtext] = useState("Show showreel")

  return  (
  <>

  <div className='screen'>

    <Canvas className='screenclass' dpr={[1,2]}    camera={{ fov: 45 }} style={{"height": "500px" } } 
    >
      <color attach="background" args={["rgb(238, 238, 238)"]} receiveShadows = {false} />
     
     
      <PresentationControls speed={1.5} global zoom={.5} polar={[-0.1, Math.PI / 4]}> 
        <Stage >
          <Model scale={0.07} receiveShadows = {false} />
        </Stage >
      </PresentationControls>
      
    </Canvas >

    <Showreel  
   source={"https://www.youtube.com/embed/oIFOlG_QZt4?si=VmXGZsfYesT7DpqR"}
   description={[""]}
   version={"handwritten"}
   height={"200px"}

   display= {showing}

   position = {"absolute"}
  top = {"-0px"}
   />

<button className='showreelbutton'
        type="button"
        onClick={function clicked(){
          if(showing == 'none'){
            setshowing("block")
            setshowingtext("Hide showreel")
        
          }
          else {
            setshowing("none");
            setshowingtext("Show showreel")
          }
          
        } }
      >{showingtext}
      </button>

   
      </div>
  </>
  )
}