import { useState } from 'react'

import '../css/videolft.css'
import React, { Component } from 'react'
import ReactPlayer from 'react-player'


export default function Videolft(props) {

  var src = props.source;
  var description = props.descriptionvid;

  return  (
  <>
  
<div className='videodivlft'>
  <span className='videocontainerlft'>

 

  <div className='playerlft'>
            <ReactPlayer
            className='videolft'
            url= {src}
            width= '30vw'
            playing
            playIcon={"true"}
           controls
          muted
          loop={"true"}

            />
    </div>

    <img src={"/images/paint mark-01.png"} className="videopaintmarklft"  title="paintmark"/>


  <span className='descriptionvideolft'>
      <p className='showreeldescriptionlft' >{description}</p>
    </span>
  
    
   
  </span>   

   
</div>
  </>
  )
}