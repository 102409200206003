import { useState } from 'react'


import '../css/middlemessage.css'

export default function Middlemessage() {

  


  return  (
  <>
    <div className="middlemessage" id="title"  >
      
     
      <h1 className="message"  >Can’t wait to hear from you!</h1>
      
     
    </div>
  </>
  )
}