
import { useState } from 'react'
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function Info() {

    return  (
    <>
     <div className='helpinfo'>
        <p className="problemsolver">I am a problem solver and happy to help any way I can</p>
            <p className="number">
            <CountUp
                  className="account-balance"
                  start={0}
                  end={10}
                  duration={2.75}
                  useEasing={true}
                  useGrouping={true}
                  separator=" "
                  redraw={true}
                 
                  suffix="+"
                  >

                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <p className="of">Years experience</p>

            <p className="number">
            <CountUp
                  className="account-balance"
                  start={0}
                  end={50}
                  duration={2.75}
                  useEasing={true}
                  useGrouping={true}
                  separator=" "
                  redraw={true}
                 
                  suffix="+"
                  >

                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <p className="of">Clients</p>

            <p className="number" id='value'>
            
            <CountUp
                  className="account-balance"
                  start={0}
                  end={200}
                  duration={2.75}
                  useEasing={true}
                  useGrouping={true}
                  separator=" "
                  redraw={true}
                 
                  suffix="+"
                  >

                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
              </CountUp>
               
            </p>
            <p className="of">Projects</p>
     </div>
    </>
    )
  }
