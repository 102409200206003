export default function Subject(props) {

     let title = props.title
     let description = props.description
     let icon = props.icon

    return  (
    <>
     <div className="subjectblock">
<img src={icon}   className="subjecticon"  title="image of jay"/>
     <h3 className="subjecttitle">{title}</h3>
     <p className="subexplain">{description}</p>
    </div>
    </>
    )
  }


