import { useState } from 'react'

import '../css/ball.css'
import React, { Component } from 'react'
import ReactPlayer from 'react-player'




export default function Ball() {

  const [ballX, setballX] = useState(10);
  const [ballY, setballY] = useState(500);
  const [direction, setdirection] = useState(1);

  document.addEventListener('scroll', function(event) {
    console.log(`Scrolled: ${event.deltaY}`);
    setballX(ballX+(30*direction))
    if(ballX> (window.screen.width-350)){
      setdirection(-1)
    }
    if(ballX< 0-150){
      setdirection(1)
    }
    
   
    
});

  return  (
  <>
  <div className='balldiv' style={{top:ballY, left:ballX}}>

    <div className='mycanvas' >
        <div className='container' >
            <div className='ball'></div>
        </div> 
        <div className='shadow'></div>  
    </div>
  
  </div>
  </>
  )
}