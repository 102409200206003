import React from 'react'
import ReactDOM from 'react-dom/client'

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Toptitle from './toptitle.jsx';
import Showreel from './showreel.jsx';
import Skills from './skills.jsx';
import Screen from './screen.jsx';
import Video from './video.jsx';
import Videolft from './videolft.jsx';
import Ball from './ball.jsx';
import Tutorial from './tutorial.jsx';
import Built from './built.jsx';
import Website from './website.jsx';
import Graphicimage from './graphicimages.jsx';

export const GraphicsPageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
    <App 
    logo = {"/images/paperboat logo-01.png"}/>
    
   <Toptitle title={"Graphic Design"}/>

 

  <TitleLft titlename={"Certifications"} />

  <Certification 
    logo={"/images/udemy.png"} 
    title={"Udemy graphic design"} 
    description={""}/>

  



<Block
   margin={'200px'}
   details={"Among my diverse skill set, graphic design stands out as a unique strength. I excel at transforming concepts into visually compelling designs, whether it’s creating sleek logos, vibrant posters, or engaging social media graphics. My keen eye for detail and passion for aesthetics allow me to blend colors, typography, and imagery seamlessly, producing stunning visuals that communicate powerful messages. This ability not only enhances brand identities but also adds a creative dimension to my multifaceted talents."}
    />

<Title title={"CV-Library"}
colour ={"white"}
 />


<Graphicimage 
description = {"Among my diverse responsibilities, I also create eye-catching graphics for CV-Library. This involves designing visuals for job postings, marketing campaigns, and social media content, enhancing the platform’s visual appeal and user experience while aligning with the brand’s identity and effectively communicating its message."}
imageone= {"/images/graphicimages/CVLimages.png"}
imagetwo= {"/images/graphicimages/CVLimages2.jpg"}
imagethree= {"/images/graphicimages/CVLimages3.jpg"}/>

<TitleLft titlename={"The BookPeople"}
colour ={"white"} />

<Graphicimage 
description = {"As part of my role, I work on graphic design projects for The Book People, creating visually appealing materials for their marketing campaigns and promotional events. My designs help to capture the essence of their brand, making their content more engaging and attractive to book lovers. "}
imageone= {"/images/graphicimages/bookpeople.jpg"}
imagetwo= {"/images/graphicimages/bookpeople2.jpg"}
imagethree= {"/images/graphicimages/bookpeople3.jpg"}/>


<Title title={"QWERKITY"}/>


<Graphicimage 
description = {"In my role, I handle graphic design projects for QWERKITY, creating engaging visuals for their unique product offerings and marketing materials. My designs help to highlight the brand’s quirky and innovative spirit, making their content more appealing and memorable to customers."}
imageone= {"/images/graphicimages/qwerkitydesign.jpg"}
imagetwo= {"/images/graphicimages/qwerkitydesign2.jpg"}
imagethree= {"/images/graphicimages/qwerkitydesign3.jpg"}/>

<TitleLft titlename={"Projects"} />

<Projects
    projectname = {["CV-Library",
       "The book People",
        "QWERKITY"
                  ]}
    projectdescription = {[
      "Designing graphics for social media, outdoor advertisements, event materials, and both internal and external communications",
        "Design social graphics for both social media campaigns and the website.",
          "Creating engaging social graphics to enhance online presence and drive audience interaction."
           
             
               
              
                
                     ]} 

    projectlogo = {["/images/complogos/cvl.jpg",
                  "/images/complogos/thebookpeople.webp",
                  "/images/complogos/qwerkity.png"
                  
              
                 
      
    ]}  />
<Title title={"Skills & software"}/>

<Skills
skills = {["Social graphics", "product graphics", "Visualisations", "Presentations"]}
logo={["/images/ai.png", "/images/pp.jpg", "/images/ind.png", "/images/canva.png" ]}
software={["Illustrator", "Photoshop", "Indesign", "Canva"]}/>

<Contact/>

</React.StrictMode>,
)

  )}