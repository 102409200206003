import { useState } from 'react'

import '../css/jay.css'

import "@fontsource/montserrat";

export default function Jay() {
  return  (
  <>
    <div className="jay" id="jay">
     <div className='jaytext'>
      <p className="mynameis">Hello my name is Jay, i am a</p>
      <h1 className="role">TECHNICAL ANIMATOR & DEVELOPER</h1>
      <h2 className="for">FOR GAMES, VIDEO & WEB</h2>
      </div>
      <div className='mepic'>
      <img src={"/images/paint mark-01.png"} className="paintmark"  title="image of jay"/>
        <img src={"/images/me.png"} className="jayimage"  title="image of jay"/>
        <img src={"/images/bass.png"}  className="jayimage" id="bass"  title="bass"/>
      
        <img src={"/images/arrow-01.png"} className="arrow"  title="arrow"/>
        <p className="playbass">I also play double bass!</p>
      </div>
    </div>
  </>
  )
}