import { useState } from 'react'

import '../css/skills.css'

import Software from './software'

export default function Skills(props) {

var skills = props.skills
var logo = props.logo
var software = props.software

let skillsList = [];

  skills.forEach((skill, index) => {
    skillsList.push(<li key={index}>{skill}</li>);
  });

  let logoList = [];

  logo.forEach((logo, index) => {
    logoList.push(<li key={index}>
      <img className='skilllogo' src={logo} /></li>);
  });

  let softwareList = [];

  software.forEach((software, index) => {
    softwareList.push(<li key={index}><p>{software}</p></li>);
  });



  return  (
  <>
  
    <div className='skills'>

      <div className='skillscontainer'>

        <span className='skillsleft'>

        <ul className='skillslist'>{skillsList}</ul>
         
        </span>

        <span className='skillsmiddle'>
            <ul className='skillslist'>{logoList}</ul>
        </span>

        <span className='skillsright'>
       
       <div className='skilldiv'>
         <ul className='skillslist'>{softwareList}</ul>
        </div>
        </span>

      </div>
      
    </div>
  </>
  )
}