import { useState } from 'react'
import ReactDOM from "react-dom/client";

import '../css/reviews.css'

var i = 0;

export default function Reviews(props) {

    var review = props.review;
    var reviewer = props.reviewer;
  var number = props.number;
   
  
  var [reviewcontent, setReview] = useState(review[i]);

  var [reviewername, setReviewname] = useState(reviewer[i]);
   
  
  delay();
  

  function delay(){
   
    
    
  setTimeout(function() {
    //alert(i);
    i++;
    
    setReview(review[i]);
    setReviewname(reviewer[i]);
    
   
      
   
    
  }, 5000)

  if(i >= number)
    {i=0
     
    }
 
}



  return  (
  <>
  
    <div className='reviews'>
       
       <div className='reviewinfo'>
       <img src={"/images/quote.svg"}   className="marks"  title="image of jay"/>
        <p className='review'>{reviewcontent}</p>
        </div>

        <div className='reviewname'>
          <div className='reviewright'>
            <p className='reviewer'>{reviewername}</p>
            <img src={"/images/bottom stroke.svg"}   className="underline"  title="image of jay"/>       
          </div>
        </div>
      
    </div>
  </>
  )
}

