import { useState } from 'react'


import '../css/showreel.css'

export default function Showreel(props) {

    let source= props.source;


    let descriptions = props.description


    let version = props.version

    let alignright = props.alignright;
    let alignleft = props.alignleft;
  
    let height = props.height;
    let colour = props.colour;
    let position = props.position
    let display = props.display;
    let center = props.center;
    let top = props.top;
    
    const dynamicStyle = {
      height: height, 
      background: colour, 
      position: position,
      display: display,
      top: top,
     }


     let descriptionList = [];

  descriptions.forEach((description, index) => {
    descriptionList.push(<li key={index}>{description}</li>);
  });
   

  return  (
  <>
    <div className="showreel" style={dynamicStyle}>
      
        <div className='showreelcontainer' >

        <img src={"/images/paint mark-01.png"} className="videopaintmark"  title="paintmark"/>

            <iframe className='videoframe' 
            src={source} 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen></iframe>
        </div>   

          <div className='descriptiondiv'>
             <img src={"/images/arrow-01.png"} className="arrow2"  title="arrow"/>
            
            <p className='showreeldescription' style={{paddingRight:alignright, paddingLeft:alignleft, textAlign:center}}>
             
            
             
          <ul  className='showreellist' >
            <li   className='showreellist'>{descriptionList}</li>

          </ul>
            
     
              </p>

           
          </div>
            
     
     
     
    </div>
  </>
  )
}